import React from "react";
import './hero-image.css'

const HeroImage = (props) => {
    const {
        Title,
        image
    } = props;

    return (
        <div className="hero-image" style={image && {backgroundImage: `url(${image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
            <h1 className="hero-image__title" dangerouslySetInnerHTML={{__html:Title}}></h1>
        </div>
    )};

export default HeroImage;