import React from "react";
import { StaticQuery, graphql } from "gatsby";
import './footer.css';

const Footer = () => (
    <StaticQuery
      query={graphql`
        query {
          strapi {
            social {
                facebook_link
                instagram_link
                linkedin_link
                telephone
                youtube_link
            }
            footer {
              copyright
              id
              logoTitle
              Logo {
                alternativeText
                url
                width
                height
              }
              linklist_1 {
                id
                href
                title
              }
              linklist_2 {
                id
                href
                title
              }
              linklist_3_title
              linklist_3 {
                id
                href
                title
              }
              linklist_4 {
                id
                href
                title
              }
              telephone
            }
          }
        }
      `}
      render={
        data => {
          const {
            copyright,
            Logo,
            logoTitle,
            telephone,
            linklist_1,
            linklist_2,
          } = data.strapi.footer;

          const {
              facebook_link,
              instagram_link,
              linkedin_link,
              youtube_link
          } = data.strapi.social;

          return (
            <footer className="footer">
              <div className="container footer__container">
                <div className="footer__left">
                  <a href="/" 
                    className="footer__logo" 
                    style={{backgroundImage: `url(${Logo?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
                      {logoTitle}
                  </a>
                  <p>
                    <a href="tel:+4408000305503" className="footer__phone">
                      {telephone}
                    </a>
                  </p>
                </div>
                <div className="footer__right">
                  <ul>
                    {linklist_1.map(link => (
                      <li key={link.id}>
                        <a href={`/${link.href}`}>{link.title}</a>
                      </li>
                    ))}
                    </ul>
                    <ul>
                      {linklist_2.map(link => (
                      <li key={link.id}>
                        <a href={`/${link.href}`}>{link.title}</a>
                      </li>
                    ))}
                    </ul>
                    <div className='footer__social'>
                      <div>
                        <h3 className='footer__social-title'>Let's Be Social</h3>
                        <ul>
                            {facebook_link && (<li>
                                <a href={facebook_link} className="footer__social-facebook full-width-book__social-facebook full-width-book__social-icon">Facebook</a>
                            </li>)}
                            {instagram_link && (<li>
                                <a href={instagram_link} className="footer__social-instagram full-width-book__social-instagram full-width-book__social-icon">Instagram</a>
                            </li>)}
                            {youtube_link && (<li>
                                <a href={youtube_link} className="footer__social-youtube full-width-book__social-youtube full-width-book__social-icon">YouTube</a>
                            </li>)}
                            {linkedin_link && (<li>
                                <a href={linkedin_link} className="footer__social-linkedin full-width-book__social-linkedin full-width-book__social-icon">LinkedIn</a>
                            </li>)}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer__copyright">
                  <p dangerouslySetInnerHTML={{__html: copyright}}></p>
                </div>
            </footer>
        )}}
    />
)

export default Footer
