import React from "react"
import PropTypes from "prop-types"

// import Seo from "./seo"
import Header from "./header/header"
import Footer from "./footer/footer"
import parse from 'html-react-parser';
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {

  if (typeof window !== `undefined`) {
    var tag = window.document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = window.document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  return (
    <StaticQuery
      query={graphql`
      query {
        strapi {
          script {
            BodyScripts
            HeadScriptss
            HeadTopScripts
          }
        }
      }`}
      render={data => {
        const {HeadScriptss,HeadTopScripts, BodyScripts} = data.strapi.script;
        return (
          <>
            <Helmet>
              <script>{HeadTopScripts}</script>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-80HSRE91MF"></script>
              <script>{HeadScriptss}</script>
              <script defer={true}>{BodyScripts}</script>
              <script src="https://www.google.com/recaptcha/api.js?render=6Ldt240lAAAAAKZM_xKH56yTpHas2B1vFYxczwlA"></script>
            </Helmet>
            {/* <Seo /> */}
            <Header />
            <main>{children}</main>
            <Footer />
          </>
      )}}
      />

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
