import React from "react";
import { StaticQuery, graphql } from "gatsby";
import './header.css';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      showEvents: false
    }
  }

  render() {
    return (
    <StaticQuery
      query={graphql`
        query {
          strapi {
            social {
                facebook_link
                instagram_link
                telephone
            }
            header {
              id
              logoTitle
              navigation {
                title
                href
                id
                childPages {
                  title
                  href
                  id
                }
              }
              subnavigation {
                title
                href
                id
                childPages {
                  title
                  href
                  id
                }
              }
            }
          }
        }
      `}
      render={
        data => (
        <header className={this.state.showMenu ? 'header active' : 'header'}>
          <div className="header__left">
            <div>
              <a href="/" className="header__logo">{data.strapi.header.logoTitle}</a>
              <nav>
                <ul>
                  {data.strapi.header.navigation.map(link => (
                    <li key={link.id}>
                      <a href={link.href === '#' ? link.href : `/${link.href}`}>{link.title}</a>
                      {link.childPages.length > 0 && ( 
                        <ul>
                          {link.childPages.map(sublink => (
                            <li key={sublink.id}>
                              <a href={sublink.href === '#' ? sublink.href : `/${sublink.href}`}>{sublink.title}</a>
                            </li>
                          ))}
                        </ul>)
                      }
                      {link.title === 'Event Types' && (
                        <ul>
                          {data.strapi.header.subnavigation.map(sublink => (
                            <li key={sublink.id}>
                              <a href={sublink.href}>{sublink.title}</a>
                              {sublink.childPages.length > 0 && ( 
                                <ul>
                                  {sublink.childPages.map(sublinklink => (
                                    <li key={sublinklink.id}>
                                      <a href={`/${sublinklink.href}`}>{sublinklink.title}</a>
                                    </li>
                                  ))}
                                </ul>)
                              }
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="header__right">
              <a href={data.strapi.social.facebook_link} className="header__facebook"></a>
              <a href={data.strapi.social.instagram_link} className="header__instagram"></a>
              <span className="header__seperator"></span>
              <a href="/contact" className="header__quote">Get a Free Quote!</a>
              <span className="header__seperator"></span>
              <a href={`phone:${data.strapi.social.telephone}`} className="header__phone">{data.strapi.social.telephone}</a>
              <button className="header__burger" onClick={() => this.setState({showMenu: !this.state.showMenu})}></button>
            </div>
            <div className="header__mobile">
              <a href="#" onClick={() => this.setState({showEvents: !this.state.showEvents})} className="header__event--mobile">Event Types</a>
              <a href="/contact" className="header__quote--mobile">Get Quote!</a>
            </div>
              
              <ul className={this.state.showEvents ? 'header__mobile-events active' : 'header__mobile-events'}>
                {data.strapi.header.subnavigation.map(sublink => (
                  <li key={sublink.id}>
                    <a href={sublink.href}>{sublink.title}</a>
                    {sublink.childPages.length > 0 && ( 
                      <ul>
                        {sublink.childPages.map(sublinklink => (
                          <li key={sublinklink.id}>
                            <a href={`/${sublinklink.href}`}>{sublinklink.title}</a>
                          </li>
                        ))}
                      </ul>)
                    }
                  </li>
                ))}
              </ul>
          </div>
          {/* <ul>
            {data.strapi.header.subnavigation.map(link => (
              <li key={link.id}>
                <a href={link.href}>{link.title}</a>
                {link.childPages.length > 0 && ( 
                  <ul>
                    {link.childPages.map(sublink => (
                      <li key={sublink.id}>
                        <a href={sublink.href}>{sublink.title}</a>
                      </li>
                    ))}
                  </ul>)
                }
              </li>
            ))}
          </ul> */}
        </header>
        )}
    />
    )
  }
}

export default Header
